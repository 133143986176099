.DirectoryComponent {
    width: 18%;
    height: 100vh;
    border-right: 1px solid var(--text-color);
}

.DirectoryArea {
    overflow: auto;
    height: calc(100% - 65px);
}

.DirectoryArea > .nodeContainer:last-child {
    margin-bottom: 115px;
}

.upload {
    position: absolute;
    display: flex;
    height: 550px;
    width: 750px;
    top: calc(50vh - 275px);
    left: calc(50vw - 375px);
    z-index: 40;
    padding: 100px;
    background-color: var(--main-bg);
    border: 1px dashed white;
    box-sizing: border-box;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.upload #filename {
    margin-left: 15px;
    padding: 2px;
}

.upload > button {
    width: 150px;
    height: 35px;
}

.upload > button:hover {
    background-color: gray;
}

.hidden {
    display: none;
}