.nodePrefix {
    width: 10px;
    display: inline-block;
    pointer-events: none;
}

.folder > .children {
    margin-left: 10px;
}

.node {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.nodeContainer:hover {
    background-color: rgba(255, 255, 255, 0.15);
}

.node > .name {
    display: inline-block;
    pointer-events: none;
    word-break: break-word;
    max-width: calc(85% - 10px);
    padding-left: 2px;
}

.node > .controls {
    display: inline-block;
    position: absolute;
    right: 10px;
    font-size: 14px;
    padding-top: 1px;
}

.controlsButton {
    background-color: var(--main-bg);
    border: 1px solid var(--text-color);
    width: 14px;
    font-size: 14px;
    text-align: center;
    display: inline-block;
}

.controlsButton:hover {
    background-color: black;
}

.controlsChildren {
    display: inline-block;
    position: fixed;
    background-color: var(--main-bg);
    z-index: 20;
    margin-left: 1px;
}