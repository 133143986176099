.control {
    text-align: center;
    cursor: pointer;
    overflow: hidden;
}

.control:hover {
    background-color: black;
}

.control.bigger {
    padding: 8px 0px;
    height: 33px;
    width: 50%;
    display: inline-block;
    border: 1px solid var(--text-color);
    box-sizing: border-box;
}

.control.border {
    padding: 2px 5px;
    border: 1px solid var(--text-color);
}

.control.chosen {
    background-color: gray;
}

.control.locked {
    background-color: hsl(0deg 0% 8%);
    color: hsl(0deg 0% 75%);
}