:root {
    --text-color: white;
    --main-bg: #2b2b2b;
}

* {
    vertical-align: baseline;
    font-family: Courier, Consolas, monospace;
    font-size: 18px;
    border: 0 none;
    outline: 0;
    padding: 0;
    margin: 0;
    font-style: normal;
    font-variant: contextual;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1;
}

.App > .Component {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    background-color: var(--main-bg);
    color: var(--text-color);
    box-sizing: border-box;
}

.title {
    padding: 3px;
    height: 24px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    text-align: center;
}

.Area {
    width: 100%;
}

.fullwidth {
    width: 100% !important;
}