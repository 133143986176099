.CodeComponent {
    width: 41%;
    height: 100vh;
    border-right: 1px solid var(--text-color);
}

.CodeArea {
    background-color: transparent;
    border: 0;
    position: relative;
    overflow: scroll;
    height: calc(100% - 65px);
}

.CodeArea:focus {
    outline: 0;
}

.codeTextAreaContainer {
    height: 0;
    width: 0;
    position: absolute;
    opacity: 0;
    z-index: -10;
}

.codeTextArea {
    height: 18px;
}

.codeLineContainer {
    min-width: 100%;
    width: fit-content;
    height: fit-content;
    min-height: calc(90vh - 49px);
    padding-bottom: 10vh;
}

.codeLine {
    min-width: 100%;
    width: max-content;
    margin-right: 10vw;
    height: 18px;
    white-space: pre-wrap;
    position: relative;
    z-index: 5;
}

.cursorContainer {
    position: absolute;
    top: 0;
    left: 0;
}

.cursor {
    top: 0;
    left: 0;
    width: 0px;
    height: 18px;
    overflow: hidden;
    z-index: 10;
    position: absolute;
    border-left: var(--text-color);
    border-left-width: 1px;
    border-left-style: solid;
    pointer-events: none;
}

.highlightContainer {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
}

.highlight {
    width: 0px;
    height: 18px;
    overflow: hidden;
    background-color: #214283;
    position: absolute;
}

.markOrange {
    color: #cc7832;
}

.markGray {
    color: #808080;
}

.markYellow {
    color: #ffc66d;
}

.codeCompletion {
    position: absolute;
    background: #46484a;
    z-index: 10;
    border: #565656 1px solid;
}

.codeCompletionLine {
    padding: 2px 10px;
}

.codeCompletionLine:hover, .codeCompletionSelected {
    background-color: #214283;
}

.VarsArea {
    height: calc(100% - 65px);
}

.varsTextArea {
    background-color: var(--main-bg);
    color: var(--text-color);
    width: 100%;
    height: 100%;
    overflow: scroll;
}